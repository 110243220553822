@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=auto");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@layer base {
  body {
    @apply font-inter;
  }
}

:root {
  --light-green-shade: #1cc9a6;
  --lighter-green-shade: rgba(28, 201, 166, 0.1);
  --white-shade: #ffffff;
  --black-shade: #000000;
  --dark-green-shade: #19b394;
  --darker-green-shade: #3c927d;
  --dark-grey-shade: #00000040;
  --light-grey-shade: #f1f1f1;
  --light-grey-auto-fill-box-shade: #e5e7eb;
  --light-grey-auto-fill-text-shade: #000;
  --darker-grey-shade: #c0bbbb;
  --dimlight-grey-shade: #cfd4da;
  --grey-gradient: 180deg, rgba(201, 200, 200, 0.56) 0%,
    rgba(201, 200, 200, 0.56) 100%;
  --light-green-gradient: 180deg, rgba(25, 179, 148, 0.56) 0%,
    rgba(238, 234, 234, 0.56) 48.13%;
  --font-hind: "Hind", sans-serif;
  --left-nav-shadow: 90deg, rgb(0 0 0 / 75%) 0%, rgba(0, 0, 0, 0) 60%;
  --dark-blue-shade: #0178bf;
  --lighter-grey-shade: #cfd4da;
  --faded-red-shade: #f6080899;
  --dark-green-gallery: #15803d;
  --dark-gray-button: #7f7f7f;
  --dark-gray-custom-css-calendar-navigation: #d9d9d9;
}

@layer components {
  .tw-btn {
    @apply px-4 py-2.5 uppercase inline-block rounded-md text-sm text-center cursor-pointer border border-transparent transition-all duration-500 font-semibold leading-normal;
  }

  .tw-btn:focus-visible {
    @apply rounded;
  }

  .tw-btn-green {
    @apply bg-green-700 hover:bg-green-500 text-white;
  }

  .tw-btn-tealgreen {
    @apply bg-[--darker-green-shade] hover:bg-[--darker-green-shade] text-white;
  }

  .tw-btn-dark {
    @apply bg-black/70 hover:bg-black/50 text-white;
  }

  .tw-input {
    @apply px-5 py-3 rounded-xl bg-black/50 focus:outline-none text-xl w-full;
  }

  .tw-input-profile,
  .custome_phone_input input {
    @apply px-4 py-2 rounded-xl bg-black/50 focus:outline-none text-xl w-full;
    border: 2px solid transparent;
  }

  .tw-input-profile:focus,
  .custome_phone_input input:focus {
    border-color: #047857;
  }

  .tw-input-profile:invalid,
  .custome_phone_input input:invalid {
    border-color: #ff0000;
  }

  .custome_phone_input .PhoneInputCountrySelect {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .tw-label {
    @apply pb-1.5 block text-white text-base;
  }

  .modules-slider .owl-carousel .owl-stage {
    @apply flex;
  }

  .modules-slider .owl-carousel .owl-stage-outer {
    @apply overflow-visible;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  /* width */
  .scrollbar::-webkit-scrollbar {
    @apply w-1.5;
  }

  /* height */
  .scrollbar::-webkit-scrollbar {
    @apply h-0;
  }

  /* Track */
  .scrollbar::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Corner */
  .scrollbar::-webkit-scrollbar-corner {
    @apply bg-transparent;
  }

  /* Handle */
  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-black/50 rounded-full transition-all duration-700;
  }

  /* Handle on hover */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-black/75;
  }

  /* width */
  .scrollbar.v-scrollbar::-webkit-scrollbar {
    @apply w-0;
  }

  /* height */
  .scrollbar.v-scrollbar::-webkit-scrollbar {
    @apply h-1.5;
  }

  /* width */
  .scrollbar.hv-scrollbar::-webkit-scrollbar {
    @apply w-1.5;
  }

  /* height */
  .scrollbar.hv-scrollbar::-webkit-scrollbar {
    @apply h-1.5;
  }

  /* .scrollbar.Journal-hover-scroll {
    overflow-y: auto;
  }

  .scrollbar.Journal-hover-scroll::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0) !important;
  }

  .Journal-hover-scroll:hover::-webkit-scrollbar-thumb {
    @apply bg-black/50 !important;
  } */

  .scrollhost {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .scrollhost::-webkit-scrollbar {
    display: none;
  }

  .jodit-editor-class {
    min-height: calc(100vh - 604px) !important;
    background-color: white !important;
  }

  .jodit-editor-class .jodit-add-new-line {
    display: none !important;
  }

  .activity-video .thumb-slider .slick-slide {
    @apply px-2.5;
  }

  .activity-video .thumb-slider {
    @apply -mx-2.5;
  }

  .activity-video .thumb-slider .slick-prev {
    @apply left-5 h-6 w-10 z-10 duration-500 transition -translate-x-0 -translate-y-1/2 hover:-translate-x-3;
  }

  .activity-video .thumb-slider .slick-next {
    @apply right-2 h-6 w-10 z-10 duration-500 transition -translate-x-3 -translate-y-1/2 hover:-translate-x-0;
  }

  .fullscreen-video video {
    @apply object-fill w-full fixed left-0;
  }

  .fullscreen-video .tuby-controls {
    @apply fixed bottom-0;
  }
}

.share-video-player-wrap .share-video-group {
  height: calc(100vh - 109px) !important;
}

.share-video-player-wrap .tuby,
.share-video-player-wrap .tuby-container,
.share-video-player-wrap video {
  height: 100% !important;
}

.share-video-player-wrap .tuby {
  padding-bottom: 0% !important;
}

.share-video-player-wrap video {
  object-fit: fill;
}

.media-center {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.media-center img,
.media-center video,
.media-center svg {
  max-width: 100%;
  height: auto;
}

.overview-calendar {
  background-color: var(--white-shade) !important;
  border: 1px solid var(--black-shade) !important;
}

.symptom-calendar {
  position: relative;
}

.symptom-calendar .react-calendar__tile {
  position: relative;
}

.symptom-calendar .highlight-date-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  /* Prevent stacking issue */
  top: 0;
  left: 0;
}

.symptom-calendar .highlight-date-content span {
  display: block;
}

.symptom-tracker-calender .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background-color: var(--white-shade) !important;
  color: var(--black-shade) !important;
}

.symptom-tracker-calender .react-calendar__tile--active {
  background-color: transparent !important;
}

.symptom-tracker-calender .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day abbr {
  background-color: #58CC02;
  padding: 6px 9px;
  color: #000;
  opacity: 0.5;
  font-weight: 600;
}

.symptom-tracker-calender .disabled-date {
  cursor: not-allowed;
  opacity: 0.5;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.8em 0.5em;
}

.overview-calendar .react-calendar__tile {
  padding: 5px !important;
}

.overview-calendar .react-calendar__year-view__months__month,
.overview-calendar .react-calendar__decade-view__years__year,
.overview-calendar .react-calendar__century-view__decades__decade {
  color: var(--black-shade);
  padding: 15px !important;
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile--now:enabled:focus {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__decade-view__years__year {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__decade-view__years__year:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__year-view__months__month {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__century-view__decades__decade {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__decade-view__years__year {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year:hover {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__navigation button:disabled {
  background-color: var(--dark-gray-custom-css-calendar-navigation) !important;
}

.overview-calendar .react-calendar__navigation {
  background-color: var(--dark-gray-custom-css-calendar-navigation);
  color: var(--black-shade);
  margin-bottom: 0;
}

.overview-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: var(--white-shade);
}

.overview-calendar .react-calendar__month-view__weekdays__weekday {
  color: var(--black-shade);
}

.overview-calendar .react-calendar__viewContainer {
  background-color: var(--white-shade);
}

.overview-calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--dark-gray-button);
}

.overview-calendar .react-calendar__tile {
  padding: 5px;
}

.goal-progress .rizzui-progressbar {
  justify-content: end;
}

.react-calendar__navigation button:disabled {
  background-color: transparent !important;
  color: gray;
}

.goal-switch-wrap label:hover .goal-switch {
  background-color: #d1d5db;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: gray;
}

@layer utilities {
  video::cue {
    background-color: rgba(0, 0, 0, 0.55);
  }

  .group_check_svg {
    height: 24px;
    width: 24px;
  }

  .journey_status_phone_input input {
    width: 100%;
  }

  .journey_status_phone_input input:focus {
    outline: none;
    box-shadow: none;
  }

  .group_check_input {
    height: 24px;
    width: 24px;
    background-color: rgb(209, 213, 219);
    border-radius: 0px !important;
  }

  input.group_check_input:checked {
    background-color: #00843d !important;
    /* background-color: #22c55e !important; */
  }

  input.notification_checkbox:checked {
    background-color: #00843d !important;
  }

  input.subscription_check_input:checked {
    background-color: #22c55e !important;
  }

  .update_goal_checkbox {
    border-color: rgb(0 0 0 / 0.2);
  }

  .checkbox_class {
    border: 1px solid black;
  }

  .stepper_scroll {

    /* WebKit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  .stepper_modal {
    background-color: #f4f4f4 !important;
    max-width: calc(100% - 50%);
    border-radius: 1.6rem;
  }

  div:has(> .stepper_modal) {
    transform: none !important;
  }

  .stepper_modal_overlay {
    background-color: rgb(0 0 0 / 0.5) !important;
  }

  body .react-calendar {
    background: rgb(14 15 15);
    color: white;
    border: none;
    border-radius: 10px;
  }

  body .react-calendar .react-calendar__tile--now {
    background: #19863c;
  }

  .date-input input {
    color: white !important;
    background-color: #000;
    display: block;
    width: 100%;
    @apply rounded-md border-0 focus:outline-1 px-4 py-2.5;
  }

  .date-input fieldset {
    border: none !important;
  }

  .fullscreen-video.without-header video {
    height: 100vh !important;
    top: 0px;
    width: 100vw !important;
    object-fit: fill !important;
  }

  /* Add this to your existing styles or create a new stylesheet */
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    color: black;
  }

  .pagination li {
    margin: 0 5px;
  }

  .pagination a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
    /* border: 1px solid #dddddd7b; */
    border-radius: 4px;
    transition: background-color 0.3s;
    color: white;
  }

  .pagination a {
    background-color: var(--dark-gray-button);
    color: white;
  }

  .pagination .previous.disabled a:hover,
  .pagination .next.disabled a:hover {
    background-color: var(--dark-gray-button);
    color: white;
  }

  .pagination .active a {
    background-color: var(--dark-green-gallery);
    color: white;
    border: 1px solid var(--dark-green-gallery);
  }

  .fullscreen-video .tuby {
    padding-bottom: inherit !important;
    height: 100vh !important;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 2px 4px 1000px var(--light-grey-auto-fill-box-shade) inset !important;
    -webkit-text-fill-color: var(--light-grey-auto-fill-text-shade) !important;
  }

  .gradient-bg {
    background: var(--activity-tile-gradient,
        linear-gradient(180deg, #03507d 0%, #001f32 100%));
  }

  .green-gradient {
    background: var(--green-gradient,
        linear-gradient(0deg, #00431f 0%, #00843d 100%));
  }

  .dark .dark\:divide-slate-700> :not([hidden])~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-divide-opacity));
  }

  .dark .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  .dark .dark\:border-slate-500 {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity));
  }

  .dark .dark\:border-slate-600 {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity));
  }

  .dark .dark\:border-slate-700 {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity));
  }

  .dark .dark\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity));
  }

  .dark .dark\:bg-slate-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity));
  }

  .dark .dark\:bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }

  .dark .bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }

  .dark .dark\:bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  }

  .dark .dark\:bg-white\/10 {
    background-color: hsla(0, 0%, 100%, 0.1);
  }

  .dark .dark\:bg-white\/20 {
    background-color: hsla(0, 0%, 100%, 0.2);
  }

  .dark .dark\:via-slate-900\/70 {
    --tw-gradient-to: rgba(15, 23, 42, 0);
    --tw-gradient-stops: var(--tw-gradient-from), rgba(15, 23, 42, 0.7),
      var(--tw-gradient-to);
  }

  .dark .dark\:to-slate-900 {
    --tw-gradient-to: #0f172a;
  }

  .dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .dark .dark\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  .dark .dark\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  .dark .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark .dark\:text-white\/50 {
    color: hsla(0, 0%, 100%, 0.5);
  }

  .dark .dark\:text-white\/70 {
    color: hsla(0, 0%, 100%, 0.7);
  }

  .dark .dark\:text-white\/80 {
    color: hsla(0, 0%, 100%, 0.8);
  }

  .dark .dark\:text-white\/90 {
    color: hsla(0, 0%, 100%, 0.9);
  }

  .dark .dark\:placeholder\:text-green-100::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity));
  }

  .dark .dark\:placeholder\:text-green-100::placeholder {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity));
  }

  .dark .dark\:hover\:bg-white\/10:hover {
    background-color: hsla(0, 0%, 100%, 0.1);
  }

  .dark .dark\:hover\:bg-white\/20:hover {
    background-color: hsla(0, 0%, 100%, 0.2);
  }

  .dark .dark\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  .dark .dark\:focus\:bg-white\/10:focus {
    background-color: hsla(0, 0%, 100%, 0.1);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  /* You can adjust the height to fit your layout */
}

.progressbar_label {
  color: black;
}

.spinner-loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.custom-loader {
  width: auto;
  height: 10px;
  border-radius: 20px;
  background: repeating-linear-gradient(135deg,
      rgb(0 132 61) 0 10px,
      #126403 0 20px) left/0% 100% no-repeat,
    repeating-linear-gradient(135deg, #766df433 0 10px, #766df41a 0 20px) left/100% 100%;
  animation: p3 2s infinite;
}

@keyframes p3 {
  100% {
    background-size: 100% 100%;
  }
}

.kommunicate-custom-iframe {
  right: 16px !important;
  bottom: 16px !important;
}

.km-iframe-closed {
  pointer-events: none;
}

.react-ios-time-picker-popup {
  align-items: start !important;
  top: 42vh !important;
  right: 2% !important;
  justify-content: right !important;
}

.highlighted-date span {
  background-color: #0178BF !important;
  color: #fff;
  border-radius: 87px;
  min-width: 20px;
  min-height: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 1600px) {
  .km-iframe-dimension-no-popup {
    width: 310px !important;
    height: 540px !important;
  }
}

@media (min-width: 1280px) {
  .kommunicate-custom-iframe {
    right: 16px !important;
    bottom: 55px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1699px) {
  .react-ios-time-picker-popup {
    right: 9.5% !important;
  }
}

@media (min-width: 1600px) and (max-width: 1799px) {
  .react-ios-time-picker-popup {
    right: 12.5% !important;
  }
}

@media (min-width: 1800px) {
  .react-ios-time-picker-popup {
    right: 13.3% !important;
  }
}

.sd-body.sd-body--static {
  padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}

.sd-footer {
  justify-content: center;
}

[type="checkbox"] {
  color: #22c55e !important;
}

[type="checkbox"]:focus {
  --tw-ring-color: none !important;
}

.sd-element--with-frame {
  border-radius: 0.5rem !important;
  box-shadow: none !important;
}

.sd-title.sd-container-modern__title {
  border-radius: 0.5rem 0.5rem 0rem 0rem !important;
}

.sd-boolean__thumb {
  background-color: #16b399 !important;
  color: white !important;
  font-weight: 500 !important;
}

.sd-title.sd-page__title,
.sd-description.sd-page__description {
  margin: auto !important;
}

.text_area_scrollbar {
  overflow: auto;
  overflow-x: hidden;
}

.container_border {
  background: linear-gradient(var(--light-green-gradient));
}

.text_area_scrollbar::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}

.text_area_scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.text_area_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #545454;
}

.goal-time-picker-wrap .react-ios-time-picker-main {
  display: none;
}

.goal-time-picker-wrap .react-ios-time-picker {
  margin-bottom: 0;
  /* width: 100%; */
}

.goal-time-picker-wrap .react-ios-time-picker .react-ios-time-picker-container {
  width: 100%;
}

.goal-time-picker-wrap .react-ios-time-picker-selected-overlay {
  margin: 0 2px;
  border-radius: 15px;
}

.react-ios-time-picker-input {
  width: 80px;
  padding: 5px 3px 3px 5px !important;
  border-radius: 3px !important;
}

.react-ios-time-picker-btn {
  color: #22c55e !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.react-ios-time-picker-btn-container {
  background-color: #f2f2f2 !important;
}

.react-ios-time-picker-cell-inner-hour:hover,
.react-ios-time-picker-cell-inner-minute:hover,
.react-ios-time-picker-cell-inner-hour-format:hover {
  background-color: #22c55e !important;
  color: black !important;
}

.react-ios-time-picker-container {
  background-color: #e4e4e4 !important;
}

[data-name="dynamic_symptoms"] .sv-ranking-item--disabled .sv-ranking-item__text {
  color: #000 !important;
  opacity: 1;
}

[data-name="dynamic_symptoms"] .sd-element__title.sd-element__title--disabled {
  opacity: 1;
}

.left-nav-background {
  background: linear-gradient(var(--left-nav-shadow));
}

/* surveyjs modal popup styles */
.sd-root-modern {
  --sjs-default-font-family: var(--font-hind) !important;
  --font-family: var(--font-hind) !important;
  --sjs-font-family: var(--font-hind) !important;
}

/* .model-survey-root__wrapper {
  border-radius: 24px;
  border: 15px solid transparent;
  background: linear-gradient(transparent 0 0) padding-box,
    linear-gradient(var(--grey-gradient)) border-box;
} */

.sd-survey__rt-container .sd-element-root__wrapper {
  background: var(--white-shade);
}

.sd-survey__rt-container .sd-element-survey__panel {
  box-shadow: none;
}

/* .sd-survey__rt-container .sd-element-survey-root-modern__wrapper {
  border-radius: 16px;
  border: 10px solid transparent;
  background: linear-gradient(transparent 0 0) padding-box,
    linear-gradient(var(--light-green-gradient)) border-box;
} */

/* .sd-element-survey-hs__wrapper {
  border-radius: 24px !important;
  border: 15px solid var(--light-grey-shade);
} */

.sd-survey__rt-container .sd-element--complex>.sd-element__header:after {
  background: none !important;
}

.sd-survey__rt-container .sd-element-survey-container__modern {
  /* box-shadow: 1px 4px 4px 1px var(--dark-grey-shade); */
  /* border: 1px solid grey; */
  background-color: var(--white-shade);
  /* border-radius: 16px; */
  /* overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 91px); */
}

.sd-survey__rt-container .sd-body {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
}

.sd-survey__rt-container .sd-element-survey__btn {
  background-color: var(--dark-green-shade);
  color: var(--white-shade);
  padding: 15px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
}

.sd-survey__rt-container .sd-question {
  text-align: left;
}

.sv-ranking .sv-ranking-item__index,
.sd-ranking .sd-ranking-item__index {
  background-color: transparent !important;
  border: 3px solid var(--light-green-shade) !important;
  color: var(--light-green-shade) !important;
}

.sv-ranking-item--disabled .sv-ranking-item__text .sv-string-viewer {
  font-weight: 600;
}

.sv-ranking .sv-ranking-item__index--empty.sd-ranking-item__index--empty,
.sd-ranking .sv-ranking-item__index--empty.sd-ranking-item__index--empty {
  background-color: var(--lighter-green-shade) !important;
  border-color: transparent !important;
}

.sd-survey__rt-container .sd-navigation__next-btn,
.sd-survey__rt-container .sd-navigation__prev-btn,
.sd-survey__rt-container .sd-navigation__start-btn,
.sd-survey__rt-container .sd-navigation__preview-btn,
.sd-survey__rt-container .sd-navigation__complete-btn {
  background-color: var(--light-green-shade);
  color: var(--white-shade);
  border-radius: 24px;
  min-width: 166px;
  width: 100%;
  cursor: pointer;
}

.sd-survey__rt-container .sd-navigation__complete-btn {
  background-color: var(--dark-green-shade);
}

.sd-survey__rt-container .sd-boolean__switch {
  background-color: var(--sjs-editorpanel-hovercolor,
      var(--sjs-general-backcolor-dim-dark, var(--darker-grey-shade)));
}

.sd-survey__rt-container .sd-html ul.info-circle-icon li::before {
  content: "";
  display: inline-block;
  height: 26px;
  width: 26px;
  vertical-align: middle;
  background-image: url("../src/Images/icons/InfoIcon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1px 8px 0px -35px;
}

.sd-survey__rt-container .sd-html ul.info-circle-icon li {
  @apply mb-2 tracking-wide;
}

.sd-survey__rt-container ul.info-circle-icon {
  margin-left: 0;
  padding-left: 34px;
}

/* surveyjs fontsizes */
.survey-font-2xl {
  @apply text-[26px] !important;
}

.survey-font-xl {
  @apply text-2xl !important;
}

.survey-font-lg {
  @apply text-lg !important;
}

.survey-font-md {
  @apply text-base !important;
}

.survey-font-sm {
  @apply text-sm !important;
}

.survey-font-green-lg {
  @apply text-green-700 text-lg !important;
}

.survey-font-green-md {
  @apply text-green-700 text-base !important;
}

[data-name="selected_treatments_in_summary"] {
  border: 1px solid var(--darker-grey-shade);
  border-radius: 6px;
  padding: 10px;
}

.modal-cross-icon:before {
  content: "✖";
  border: 1px solid var(--darker-green-shade);
  border-radius: 5px;
  background-color: var(--darker-green-shade);
  color: var(--white-shade);
  padding: 0.2rem 0.4rem;
  cursor: pointer;
}

.dashboard-all-tabs {
  border-bottom: 2.5px solid var(--dimlight-grey-shade);
}

.summaries-tab-dashboard {
  border-bottom: 2px solid var(--dimlight-grey-shade);
}

.tab-active-border::before {
  background: var(--dark-blue-shade) !important;
}

.tab-inactive-border::before {
  background: var(--dark-blue-shade) !important;
}

/* .tab-inactive-color {
  color: var(--lighter-grey-shade);
} */

.journal-chart .recharts-wrapper {
  width: 100% !important;
  max-width: 700px !important;
  margin: 0 auto !important;
}

.goal-tab-container .sd-root-modern__wrapper {
  padding: 0 1rem;
}

.health-question-symptom-highlight {
  background-color: var(--faded-red-shade);
}

intake-content input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.journal-tab-container *,
.overview-tab-container *,
.dashboard-tab-container *,
.symptom-tracker-tab-container * {
  font-family: "Rubik", sans-serif !important;
}

.jodit-ui-checkbox__input {
  appearance: auto !important;
}

.jodit-editor-class ul,
.jodit-editor-class ol {
  margin-left: 20px;
  list-style-position: inside;
}

.jodit-editor-class ul {
  list-style: disc;
}

.jodit-editor-class ol {
  list-style: decimal;
}

.jodit-editor-class h1,
.jodit-editor-class h2,
.jodit-editor-class h3,
.jodit-editor-class h4,
.jodit-editor-class h5,
.jodit-editor-class h6,
.jodit-editor-class p {
  /* Your styles here */
  font-size: revert-layer;
}

.jodit-editor-class blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  unicode-bidi: isolate;
}